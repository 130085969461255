<div class="entity-table-component">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
  >
    <ng-container matColumnDef="selected" *ngIf="allowMultiSelect">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllTestEventsSelected() : null"
                      [checked]="selectedTestEvents.hasValue() && areAllTestEventsSelected()"
                      [indeterminate]="selectedTestEvents.hasValue() && !areAllTestEventsSelected()"
                      [aria-label]="'message.test.event-table.aria-labels.toggle-all-selected' | translate">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selectedTestEvents.toggle(row) : null"
                      [checked]="selectedTestEvents.isSelected(row)"
                      [aria-label]="'message.test.event-table.aria-labels.toggle-single-selected' | translate">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.id' | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.testEventId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="testarea">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.test-location'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.testareaName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="eventdate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.event-date'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{
          row.testEventTime
            | localizedDate: 'mediumDate'
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="eventtime">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.event-time'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{
          row.testEventTime
            | localizedDate: 'shortTime'
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="testcenter">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.test-center'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.testCenterName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="maxCapacity">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.max-capacity'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.maximumCapacity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfBookings">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.booked-already'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{
          row.numberOfBookings
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="utilizationRatio">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'table.change-booking.header.utilization'
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{
          row.utilizationRatio
            | percent: undefined:currentLanguage
        }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="rowsConfig"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: rowsConfig"
      (click)="newEventChosen(row)"
      class="hover-outline ripple clickable"
      [class.active-row]="
              row.testEventId === activeRowId
            "
      [class.bookedEvent]="
              row.testEventId === currentTestEventId
            "
    ></tr>
  </table>
</div>
