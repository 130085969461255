import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { NGXLogger } from 'ngx-logger';
import Tools from '../../../lib/Tools';
import {
  ExamEventService,
  ExamService,
  NotificationService,
} from '../../../services';
import {
  ExamCenter,
  ExamEvent,
  SelectOption,
} from '../../../types';

@Component({
  selector: 'app-exam-event-form',
  templateUrl: './exam-event-form.component.html',
  styleUrls: ['./exam-event-form.component.scss'],
})
export class ExamEventFormComponent
  implements OnInit, AfterViewInit
{
  @Input() examCenter!: ExamCenter;
  @Output()
  closeForm: EventEmitter<void> = new EventEmitter<void>();

  startingPointForRegistrationPeriodEnd: Date | null = null;

  currency = 'EUR';
  defaultPrice!: number;

  get examSelectOptions(): SelectOption[] {
    return this.examService.examSelectOptions;
  }

  private form!: UntypedFormGroup;

  constructor(
    private examService: ExamService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private note: NotificationService,
    private service: ExamEventService
  ) {}

  ngOnInit(): void {
    if (this.item.id > 0) {
      this.form = this.formBuilder.group({
        exam: [this.item.testId],
        time: [
          Tools.dateFromIso(this.item.testeventTime),
          [Validators.required],
        ],
        registrationPeriodStart: [
          Tools.dateFromIso(this.item.registerTimeFrom),
          [Validators.required],
        ],
        registrationPeriodEnd: [
          Tools.dateFromIso(this.item.registerTimeTo),
          [Validators.required],
        ],
        capacity: [
          this.item.maximumCapacity,
          [Validators.required],
        ],
        defaultPrice: [
          this.item.price.amount,
          [Validators.min(0)],
        ],
        defaultPriceCheckbox: [],
        reducedPrice: [
          this.item.reducedPrice?.amount,
          [Validators.min(0)],
        ],
        reducedPriceCheckbox: [],
      });
      this.setStartingPointForRegistrationPeriodEnd(
        Tools.dateFromIso(this.item.registerTimeFrom)
      );

      this.getDefaultPrice(this.item.testId).then(() => {
        this.defaultPriceCheckboxControl.setValue(
          this.item.price.amount === this.defaultPrice
        );
      });
    } else {
      this.form = this.formBuilder.group({
        exam: [this.item.testId],
        time: [null, [Validators.required]],
        registrationPeriodStart: [
          null,
          [Validators.required],
        ],
        registrationPeriodEnd: [
          null,
          [Validators.required],
        ],
        capacity: [
          this.examCenter.maximumCapacity,
          [Validators.required],
        ],
        defaultPrice: [
          this.item.price.amount,
          [Validators.min(0)],
        ],
        defaultPriceCheckbox: [true],
        reducedPrice: [
          this.item.reducedPrice?.amount,
          [Validators.min(0)],
        ],
        reducedPriceCheckbox: [false],
      });
    }
  }

  ngAfterViewInit(): void {
    this.registrationPeriodStartControl.valueChanges.subscribe(
      (registrationStart) => {
        this.setStartingPointForRegistrationPeriodEnd(
          registrationStart
        );
      }
    );
  }

  testSelected(event: MatSelectChange): void {
    this.getDefaultPrice(event.value).then(() =>
      this.defaultPriceCheckboxControl.setValue(true)
    );
  }

  async getDefaultPrice(testId: number): Promise<void> {
    try {
      await this.examService.loadTemplateOptions();
      const testTemplates = this.examService.testTemplates;
      const selectedTest =
        await this.examService.loadExamById(testId);

      const matchingTemplateInfos = testTemplates.filter(
        (template) =>
          template.templateName ===
          selectedTest.templateName
      );

      if (matchingTemplateInfos.length === 1) {
        const { currency, amount } =
          matchingTemplateInfos[0].defaultPrice;
        this.currency = currency;
        this.defaultPrice = amount;
      } else {
        this.logger.error(
          `${matchingTemplateInfos.length} matching templates found, could not set the default price.`
        );
      }
      // Check, whether we have a reduced price already
      this.reducedPriceCheckboxControl.setValue(
        this.item.reducedPrice !== undefined &&
          this.item.reducedPrice !== null
      );
    } catch {
      this.fail();
    }
  }

  get showFormFields(): boolean {
    return (
      this.examControl.value > 0 && this.defaultPrice >= 0
    );
  }

  get headline(): string {
    return this.item.id > 0
      ? 'label.edit-exam-event'
      : 'label.create-exam-event';
  }

  get deleteButtonDisabled(): boolean {
    return this.item.id < 1;
  }

  get item(): ExamEvent {
    return this.service.formState.item;
  }

  get examControl(): UntypedFormControl {
    return this.form.get('exam') as UntypedFormControl;
  }

  get capacityControl(): UntypedFormControl {
    return this.form.get('capacity') as UntypedFormControl;
  }

  get defaultPriceCheckboxControl(): FormControl<boolean> {
    return this.form.get(
      'defaultPriceCheckbox'
    ) as FormControl<boolean>;
  }

  get defaultPriceControl(): FormControl<number> {
    return this.form.get(
      'defaultPrice'
    ) as FormControl<number>;
  }

  get reducedPriceCheckboxControl(): FormControl<boolean> {
    return this.form.get(
      'reducedPriceCheckbox'
    ) as FormControl<boolean>;
  }

  get reducedPriceControl(): FormControl<number> {
    return this.form.get(
      'reducedPrice'
    ) as FormControl<number>;
  }

  get timeControl(): FormControl<string> {
    return this.form.get('time') as FormControl<string>;
  }

  get registrationPeriodStartControl(): UntypedFormControl {
    return this.form.get(
      'registrationPeriodStart'
    ) as UntypedFormControl;
  }

  get registrationPeriodEndControl(): UntypedFormControl {
    return this.form.get(
      'registrationPeriodEnd'
    ) as UntypedFormControl;
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.item.testcentersId = this.examCenter.id;
    this.item.testId = this.examControl.value;
    this.item.maximumCapacity = Number(
      this.capacityControl.value
    );
    this.item.price.amount = this
      .defaultPriceCheckboxControl.value
      ? this.defaultPrice
      : this.defaultPriceControl.value;
    if (
      this.reducedPriceCheckboxControl.value &&
      this.reducedPriceControl.value !== undefined
    ) {
      this.item.reducedPrice = {
        amount: this.reducedPriceControl.value,
        currency: 'EUR',
      };
    } else {
      this.item.reducedPrice = undefined;
    }
    this.item.testeventTime = Tools.dateToIso(
      this.timeControl.value
    );
    this.item.registerTimeFrom = Tools.dateToIso(
      this.registrationPeriodStartControl.value
    );
    this.item.registerTimeTo = Tools.dateToIso(
      this.registrationPeriodEndControl.value
    );
    this.service
      .save()
      .then(() => this.success())
      .catch(() => this.fail());
  }

  success(): void {
    this.closeForm.emit();
  }

  fail(): void {
    this.logger.error('save failed');
  }

  delete(): void {
    this.note
      .confirm({ message: 'label.confirm-delete' })
      .then((result) => {
        this.logger.debug('answer is', { result });
        if (result) {
          this.logger.debug('do the delete');
          this.service
            .delete(this.item.id)
            .then(() => this.closeForm.emit());
        }
      });
  }

  private setStartingPointForRegistrationPeriodEnd(
    registrationStart: Date
  ) {
    this.startingPointForRegistrationPeriodEnd =
      registrationStart;
    if (
      registrationStart >
      this.registrationPeriodEndControl.value
    ) {
      this.form.controls.registrationPeriodEnd.setValue(
        null
      );
    }
  }
}
