<form [formGroup]="form">
  <div class="form-content">
    <!-- Question "Enrolled in first or second semester" -->
    <app-form-field-radio
      class="width100"
      [field]="enrolledInFirstOrSecondSemesterControl"
      [options]="radioBtnYesNoOptions"
      [color]="'accent'"
      label="booking.sdv.prerequisites-check-page.enrolledInFirstOrSecondSemester.question"
      hint="booking.sdv.prerequisites-check-page.enrolledInFirstOrSecondSemester.hint"
    >
      <span hint-link>
        <a
          href="{{
            'booking.sdv.prerequisites-check-page.enrolledInFirstOrSecondSemester.link.link'
              | translate
          }}"
          target="_blank"
        >
          {{
            'booking.sdv.prerequisites-check-page.enrolledInFirstOrSecondSemester.link.text'
              | translate
          }}
        </a>
      </span>
    </app-form-field-radio>

    <app-requirements-check-decline-message
      *ngIf="
        enrolledInFirstOrSecondSemesterControl.value ===
        false
      "
      [question]="'enrolledInFirstOrSecondSemester'"
    ></app-requirements-check-decline-message>

    <!-- Question "German nationality" -->
    <ng-container
      *ngIf="
        hasGermanNationalityVisible
      "
    >
      <app-form-field-radio
        class="width100"
        [field]="
          hasGermanNationalityControl
        "
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
        (change)="resetCheckPrerequisitesTrigger()"
        label="booking.sdv.prerequisites-check-page.germanNationality.question"
      >
      </app-form-field-radio>
    </ng-container>

    <!-- Question "Studying primarily in Germany / EU / Switzerland" -->
    <ng-container *ngIf="isStudiedInEuOrSwitzerlandVisible">
      <app-form-field-radio
        class="width100"
        [field]="studiedInEuOrSwitzerlandControl"
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
        label="booking.sdv.prerequisites-check-page.studiedInEuOrSwitzerland.question"
      >
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="
          studiedInEuOrSwitzerlandControl.value === false
        "
        [question]="'studiedInEuOrSwitzerland'"
      ></app-requirements-check-decline-message>
    </ng-container>

    <!-- Question "Permitted to receive BAföG" -->
    <ng-container *ngIf="isPermittedToReceiveBafoegVisible">
      <app-form-field-radio
        class="width100"
        [field]="permittedToReceiveBafoegControl"
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
      >
        {{
          'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.part-1' | translate
        }}
        <a
          href="{{
            'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.link-1.link'
              | translate
          }}"
          target="_blank"
        >
          {{
            'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.link-1.text'
              | translate
          }}
        </a>
        <strong>
          {{
          'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.part-2' | translate
          }}
        </strong>
        <a
          href="{{
            'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.link-2.link'
              | translate
          }}"
          target="_blank"
        >
          {{
            'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.link-2.text'
              | translate
          }}
        </a>
        <strong>
          {{
          'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.part-3' | translate
          }}
        </strong>
        {{
        'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.question.part-4' | translate
        }}
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="
          permittedToReceiveBafoegControl.value === false
        "
        [question]="'permittedToReceiveBafoeg'"
      ></app-requirements-check-decline-message>
    </ng-container>

    <!-- Question "Studying primarily in Germany" -->
    <ng-container *ngIf="isStudiesPrimarilyInGermanyVisible">
      <app-form-field-radio
        class="width100"
        [field]="studiesPrimarilyInGermanyControl"
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
        label="booking.sdv.prerequisites-check-page.studiesPrimarilyInGermany.question"
      >
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="
          studiesPrimarilyInGermanyControl.value === false
        "
        [question]="'studiesPrimarilyInGermany'"
      ></app-requirements-check-decline-message>
    </ng-container>

    <!-- Question "type of school" -->
    <ng-container *ngIf="isTypeOfSchoolVisible">
      <div fxLayout="column">
        <mat-label
          [id]="
            'booking.sdv.prerequisites-check-page.typeOfSchool.question'
              | translate
          "
          >{{
            'booking.sdv.prerequisites-check-page.typeOfSchool.question'
              | translate
          }}
          <em>
            <mat-hint>{{
              'booking.sdv.prerequisites-check-page.typeOfSchool.hint'
                | translate
            }}</mat-hint>
          </em>
        </mat-label>
        <app-form-field-select
          label="label.dropdown-select"
          [field]="typeOfSchoolControl"
          [options]="typeOfSchoolOptions"
        >
        </app-form-field-select>

        <app-requirements-check-decline-message
          *ngIf="
            typeOfSchoolControl.value?.toString() ===
              'dual-studies.university-of-cooperative-education' ||
            typeOfSchoolControl.value?.toString() === 'art-college' ||
            typeOfSchoolControl.value?.toString() === 'conservatory'
          "
          [question]="typeOfSchoolControl.value?.toString() ?? ''"
        ></app-requirements-check-decline-message>
      </div>
    </ng-container>

    <!-- Question "form of studies" -->
    <ng-container *ngIf="isFormOfStudiesVisible">
      <app-form-field-radio
        class="width100"
        [field]="formOfStudiesControl"
        [options]="radioBtnFormOfStudiesOptions"
        [color]="'accent'"
        label="booking.sdv.prerequisites-check-page.formOfStudies.question"
      >
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="formOfStudiesControl.value?.toString() === 'part-time'"
        [question]="'formOfStudies'"
      ></app-requirements-check-decline-message>
    </ng-container>

    <!-- Question "have completed academic studies" -->
    <ng-container *ngIf="isAcademicStudiesCompletedVisible">
      <app-form-field-radio
        class="width100"
        [field]="academicStudiesCompletedControl"
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
        label="booking.sdv.prerequisites-check-page.academicStudiesCompleted.question"
      >
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="academicStudiesCompletedControl.value"
        [question]="'academicStudiesCompleted'"
      ></app-requirements-check-decline-message>
    </ng-container>

    <!-- Question "previously suggested for SdV" -->
    <ng-container *ngIf="isSuggestedForSdVVisible">
      <app-form-field-radio
        (change)="checkPrerequisites()"
        class="width100"
        [field]="suggestedForSdVControl"
        [options]="radioBtnYesNoOptions"
        [color]="'accent'"
      >
        {{ 'booking.sdv.prerequisites-check-page.suggestedForSdV.question.part-1' | translate }}
        <a href="{{ 'booking.sdv.prerequisites-check-page.suggestedForSdV.question.link' | translate }}"
          target="_blank">
          {{ 'booking.sdv.prerequisites-check-page.suggestedForSdV.question.text' | translate }}
        </a>
        {{ 'booking.sdv.prerequisites-check-page.suggestedForSdV.question.part-2' | translate }}
      </app-form-field-radio>

      <app-requirements-check-decline-message
        *ngIf="suggestedForSdVControl.value"
        [question]="'suggestedForSdV'"
      ></app-requirements-check-decline-message>
    </ng-container>
  </div>

  <mat-card-actions fxLayout fxLayoutAlign="space-between">
    <button
      mat-raised-button
      color="warn"
      (click)="cancelBooking()"
    >
      {{ 'button.cancel' | translate }}
    </button>
    <div>
      <button
        mat-raised-button
        matStepperPrevious
        type="button"
      >
        {{ 'button.back' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="submit()"
        [disabled]="!isNextEnabled"
      >
        {{ 'button.next' | translate }}
      </button>
    </div>
  </mat-card-actions>
</form>
