import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  BookingService,
  CountryService,
  GenderService,
  NotificationService,
  ProfileService,
  SnackBarService,
  UserService,
} from '../../../services';
import {
  EventBooking,
  FormValidationErrorResponse,
  ListResponse,
  ParentalAddressInfo,
  RadioOption,
  SelectOption,
  User,
} from '../../../types';
import { EditEmailDialogComponent } from '../../dialogs';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent
  implements OnInit, OnDestroy
{
  @HostBinding('class.form-component') hostClass = true;
  form!: UntypedFormGroup;

  @Input() includeDeletedBookings = false;
  @Input() includeIncompleteBookings = false;

  parentalAddressesOptions: RadioOption[] = [
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.yes',
      value: true,
    },
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.no',
      value: false,
    },
  ];
  filteredBookings: EventBooking[] = [];

  private includeInactiveBookings = false;
  private isCandidateSubscription!: Subscription;
  private allBookings: ListResponse<EventBooking> = {
    items: [],
    total: 0,
  };

  constructor(
    private bookingService: BookingService,
    private breadcrumbService: BreadcrumbService,
    private countryService: CountryService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private genderService: GenderService,
    private logger: NGXLogger,
    private note: NotificationService,
    private profileService: ProfileService,
    private router: Router,
    private snackBar: SnackBarService,
    private userService: UserService
  ) {}

  get canDelete(): boolean {
    // check the user is the same as the logged in user
    return (
      this.user.id > 0 &&
      this.user.id !== this.profileService.profile.user.id
    );
  }

  ngOnInit(): void {
    this.bookingService
      .loadBookingsList(this.user.id, {
        incompleteBookings: this.includeIncompleteBookings,
        deletedBookings: this.includeDeletedBookings,
      })
      .then((bookings) => {
        this.allBookings = bookings;
        this.filteredBookings =
          this.allBookings.items.filter(
            (booking) =>
              this.includeInactiveBookings ||
              (!booking.deleted &&
                booking.status !== 'order_canceled')
          );
      });

    this.form = this.formBuilder.group({
      // block: personal
      email: [
        this.user.email,
        [Validators.required, Validators.email],
      ],
      firstname: [
        this.user.firstname,
        [Validators.required],
      ],
      lastname: [this.user.lastname, [Validators.required]],
      birthName: [this.user.birthName],
      gender: [this.user.gender],
      birthLocation: [this.user.birthplace],
      birthDate: [this.user.birthday],
      phone: [this.user.phoneNumber],
      email2: [this.user.secondEmail],

      // block: address
      street: [this.user.street],
      streetNumber: [this.user.streetNumber],
      addressAddition: [this.user.additionalAddressInfo],
      postalCode: [
        this.user.zip,
        [Validators.maxLength(10)],
      ],
      city: [this.user.city],
      state: [this.user.state],
      country: [this.user.country],

      // parental Addresses
      parentalAddressesRadio: [
        !!this.user.parentalAddresses.length,
      ],
      parentalAddresses: this.formBuilder.array(
        this.user.parentalAddresses.map((address) =>
          this.createParentalAddressFormGroup(address)
        )
      ),

      // block: flags
      roleAdmin: [this.user.admin],
      roleCandidate: [this.user.candidate],
    });

    this.isCandidateSubscription =
      this.roleCandidateControl.valueChanges.subscribe(
        (value) => this.toggleCandidateValidations(value)
      );

    this.emailControl.disable();

    this.breadcrumbService.set(
      '@userName',
      `${this.user.firstname} ${this.user.lastname}`
    );

    this.firstnameControl.valueChanges.subscribe(
      (firstName) => {
        this.breadcrumbService.set(
          '@userName',
          `${firstName} ${this.lastnameControl.value}`
        );
      }
    );

    this.lastnameControl.valueChanges.subscribe(
      (lastName) => {
        this.breadcrumbService.set(
          '@userName',
          `${this.firstnameControl.value} ${lastName}`
        );
      }
    );

    this.setTenantValidations(this.tenant);

    this.parentalAddressesRadioControl.valueChanges.subscribe(
      (isParentalAddresses) => {
        if (
          isParentalAddresses &&
          this.user.parentalAddresses.length === 0
        ) {
          this.addParentalAddress();
        } else if (!isParentalAddresses) {
          this.removeParentalAddresses();
        }
      }
    );
  }

  get user(): User {
    return this.userService.formState.item;
  }

  get isCreateMode(): boolean {
    return this.user.id < 1;
  }

  get genderOptions(): SelectOption[] {
    return this.genderService.selectOptions;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }

  // block: base
  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }
  get firstnameControl(): UntypedFormControl {
    return this.form.get('firstname') as UntypedFormControl;
  }
  get lastnameControl(): UntypedFormControl {
    return this.form.get('lastname') as UntypedFormControl;
  }
  get birthNameControl(): UntypedFormControl {
    return this.form.get('birthName') as UntypedFormControl;
  }

  // block: personal
  get genderControl(): UntypedFormControl {
    return this.form.get('gender') as UntypedFormControl;
  }
  get birthLocationControl(): UntypedFormControl {
    return this.form.get(
      'birthLocation'
    ) as UntypedFormControl;
  }
  get birthDateControl(): UntypedFormControl {
    return this.form.get('birthDate') as UntypedFormControl;
  }
  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }
  get email2Control(): UntypedFormControl {
    return this.form.get('email2') as UntypedFormControl;
  }

  // block: address
  get streetControl(): UntypedFormControl {
    return this.form.get('street') as UntypedFormControl;
  }
  get streetNumberControl(): UntypedFormControl {
    return this.form.get(
      'streetNumber'
    ) as UntypedFormControl;
  }
  get addressAdditionControl(): UntypedFormControl {
    return this.form.get(
      'addressAddition'
    ) as UntypedFormControl;
  }
  get postalCodeControl(): UntypedFormControl {
    return this.form.get(
      'postalCode'
    ) as UntypedFormControl;
  }
  get stateControl(): UntypedFormControl {
    return this.form.get('state') as UntypedFormControl;
  }
  get cityControl(): UntypedFormControl {
    return this.form.get('city') as UntypedFormControl;
  }
  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }
  get parentalAddressesRadioControl(): UntypedFormControl {
    return this.form.get(
      'parentalAddressesRadio'
    ) as UntypedFormControl;
  }
  get parentalAddressesFormArray(): FormArray<UntypedFormGroup> {
    return this.form.controls[
      'parentalAddresses'
    ] as FormArray<UntypedFormGroup>;
  }

  // block: flags
  get roleAdminControl(): UntypedFormControl {
    return this.form.get('roleAdmin') as UntypedFormControl;
  }

  get roleCandidateControl(): UntypedFormControl {
    return this.form.get(
      'roleCandidate'
    ) as UntypedFormControl;
  }

  get showDeletedBookings(): boolean {
    return this.includeInactiveBookings;
  }

  set showDeletedBookings(showDeleted: boolean) {
    this.includeInactiveBookings = showDeleted;
    this.filteredBookings = this.allBookings.items.filter(
      (booking) =>
        showDeleted ||
        (!booking.deleted &&
          booking.status !== 'order_canceled')
    );
  }

  get isLoading(): boolean {
    return this.bookingService.listState.loading;
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.userService.formState.item = {
      ...this.userService.formState.item,

      // block: base
      firstname: this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      birthName: this.birthNameControl.value,

      // block: personal
      gender: this.genderControl.value,
      birthplace: this.birthLocationControl.value,
      birthday: this.birthdayFormated,
      phoneNumber: this.phoneControl.value,
      secondEmail: this.email2Control.value,

      // block: address
      street: this.streetControl.value,
      streetNumber: this.streetNumberControl.value,
      additionalAddressInfo:
        this.addressAdditionControl.value,
      zip: this.postalCodeControl.value,
      city: this.cityControl.value,
      state: this.stateControl.value,
      country: this.countryControl.value,
      parentalAddresses:
        this.parentalAddressesFormArray.value,

      admin: this.roleAdminControl.value,
      candidate: this.roleCandidateControl.value,
    };

    this.userService
      .save()
      .then(() => this.success())
      .catch((e: FormValidationErrorResponse) =>
        this.fail(e)
      );
  }

  get birthdayFormated(): string {
    if (this.birthDateControl.value instanceof Date) {
      const birthday = this.birthDateControl.value;
      return (
        birthday.getFullYear() +
        '-' +
        ('0' + (birthday.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + birthday.getDate()).slice(-2)
      );
    } else {
      return this.birthDateControl.value;
    }
  }

  delete(): void {
    this.note
      .confirm({ message: 'label.confirm-delete-user' })
      .then((result) => {
        if (result) {
          this.userService.delete(this.user.id).then(() => {
            this.router.navigate(['/home/user']);
          });
        }
      });
  }

  editEmail(): void {
    this.dialog.open(EditEmailDialogComponent, {
      width: '450px',
      autoFocus: false,
    });
  }

  resetPassword(): void {
    this.note
      .confirm({ message: 'label.confirm-reset-password' })
      .then((result) => {
        if (result) {
          this.userService
            .sendPasswordResetMail(this.user)
            .then((_response) => {
              this.note.success({
                message:
                  'message.get-reset-password-successful.' +
                  this.tenant,
              });
            })
            .catch(() => this.note.error('error.general'));
        }
      });
  }

  ngOnDestroy(): void {
    this.isCandidateSubscription?.unsubscribe();
  }

  private toggleCandidateValidations(
    isCandidate: boolean
  ): void {
    // set the validators
    if (isCandidate) {
      this.genderControl.setValidators([
        Validators.required,
      ]);
      this.birthDateControl.setValidators([
        Validators.required,
      ]);
      this.birthLocationControl.setValidators([
        Validators.required,
      ]);
      this.phoneControl.setValidators([
        Validators.required,
      ]);
      this.streetControl.setValidators([
        Validators.required,
      ]);
      this.streetNumberControl.setValidators([
        Validators.required,
      ]);
      this.postalCodeControl.setValidators([
        Validators.required,
      ]);
      this.cityControl.setValidators([Validators.required]);
      this.stateControl.setValidators([
        Validators.required,
      ]);
      this.countryControl.setValidators([
        Validators.required,
      ]);
    } else {
      this.genderControl.clearValidators();
      this.birthDateControl.clearValidators();
      this.birthLocationControl.clearValidators();
      this.phoneControl.clearValidators();
      this.streetControl.clearValidators();
      this.streetNumberControl.clearValidators();
      this.postalCodeControl.clearValidators();
      this.cityControl.clearValidators();
      this.stateControl.clearValidators();
      this.countryControl.clearValidators();
    }

    // is needed to apply ths validation changes
    this.genderControl.updateValueAndValidity();
    this.birthDateControl.updateValueAndValidity();
    this.birthLocationControl.updateValueAndValidity();
    this.phoneControl.updateValueAndValidity();
    this.streetControl.updateValueAndValidity();
    this.streetNumberControl.updateValueAndValidity();
    this.postalCodeControl.updateValueAndValidity();
    this.cityControl.updateValueAndValidity();
    this.stateControl.updateValueAndValidity();
    this.countryControl.updateValueAndValidity();
  }

  private setTenantValidations(tenant: string): void {
    switch (tenant) {
      case 'sdv':
        this.parentalAddressesRadioControl.setValidators([
          Validators.required,
        ]);
        break;
      default:
        this.parentalAddressesRadioControl.clearValidators();
    }

    this.parentalAddressesRadioControl.updateValueAndValidity();
  }

  private createParentalAddressFormGroup(
    address: ParentalAddressInfo
  ): FormGroup {
    return this.formBuilder.group({
      additionalAddressInfo: [
        address.additionalAddressInfo,
      ],
      city: [address.city],
      country: [address.country],
      state: [address.state],
      street: [address.street],
      streetNumber: [address.streetNumber],
      zip: [address.zip],
    });
  }

  private addParentalAddress(): void {
    const parentAddress = this.formBuilder.group({
      street: ['', Validators.required],
      streetNumber: ['', Validators.required],
      additionalAddressInfo: [''],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      country: ['', Validators.required],
    });

    this.parentalAddressesFormArray.push(parentAddress);
  }

  private removeParentalAddresses(): void {
    this.parentalAddressesFormArray.clear();
    this.userService.formState.item.parentalAddresses = [];
  }

  private success(): void {
    this.snackBar.showSnackBar(
      'message.profile-edit.success'
    );
    this.router.navigate(['/home/user']);
  }

  private fail(errors: FormValidationErrorResponse): void {
    this.logger.error('UserFormComponent:handleErrors', {
      errors,
    });
    errors.forEach((error) => {
      const field = this.form.get(
        error.field
      ) as UntypedFormControl;
      field.setErrors({ custom: error.message });
    });
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
