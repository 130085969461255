import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  ExamEventService,
  NotificationService,
  StatisticsSearchFilters,
} from 'src/app/services';
import { EventBooking, ExamEvent } from 'src/app/types';
import { ChangeExamEventRequest } from 'src/app/types/request/ChangeExamEventRequest';
import { LoadingDialogComponent } from '..';

@Component({
  selector: 'app-change-booking-dialog',
  templateUrl: './change-booking-dialog.component.html',
  styleUrls: ['./change-booking-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeBookingDialogComponent
  implements OnInit
{
  bookingToUpdate!: EventBooking;
  oldEvent!: ExamEvent;
  newEvent?: ExamEvent;

  constructor(
    private bookingService: BookingService,
    private examEventService: ExamEventService,
    private logger: NGXLogger,
    private notification: NotificationService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.bookingToUpdate =
      this.bookingService.formState.item;
  }

  async setActiveEvent(
    activeEventId: number
  ): Promise<void> {
    try {
      this.oldEvent =
        await this.examEventService.loadByEventId(
          activeEventId
        );
    } catch (e) {
      this.logger.error(e);
      this.dialog.closeAll();
      this.notification.error('error.general');
    }
  }

  async newEventChosen(eventId: number): Promise<void> {
    try {
      this.newEvent =
        await this.examEventService.loadByEventId(eventId);
      this.examEventService.formState.item = this.newEvent;
    } catch (e) {
      this.logger.error(e);
      this.dialog.closeAll();
      this.notification.error('error.general');
    }
  }

  async changeBooking(): Promise<void> {
    if (!this.newEvent) {
      console.error(
        'Tried to change the test event, but no new event was selected.'
      );
      return;
    }

    this.dialog.open(LoadingDialogComponent, {
      minWidth: '300px',
      disableClose: true,
    });

    const body: ChangeExamEventRequest = {
      testEventId: this.newEvent.id,
    };

    await this.bookingService
      .changeTestEvent(this.bookingToUpdate.bookingId, body)
      .then(() => {
        this.dialog.closeAll();
        this.notification.success({
          message: 'message.booking-event-changed',
        });
      })
      .catch((e) => {
        this.logger.error(e);
        this.dialog.closeAll();
        this.notification.error('error.general');
      });
  }

  get statisticsSearchFilters(): StatisticsSearchFilters {
    return {
      testIds: [this.bookingToUpdate.test.id],
    };
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
