import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CheckboxValidator } from '../../../lib/form-fields';
import {
  CountryService,
  GenderService,
  UserService,
} from '../../../services';
import {
  FormValidationErrorResponse,
  SelectOption,
  User,
} from '../../../types';

@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.scss'],
})
export class CandidateFormComponent implements OnInit {
  @HostBinding('class.form-component') hostClass = true;

  private form!: UntypedFormGroup;
  constructor(
    private service: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private countryService: CountryService,
    private genderService: GenderService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        this.item.email,
        [Validators.required, Validators.email],
      ],
      firstname: [
        this.item.firstname,
        [Validators.required],
      ],
      lastname: [this.item.lastname, [Validators.required]],
      isAdmin: [false],
      roleAdmin: [false],
      roleCandidate: [true],
      confirmedGDPR: [
        this.item.confirmedGDPR,
        [CheckboxValidator.checked],
      ],
      gender: [''],
      birthLocation: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email2: [''],
      street: ['', [Validators.required]],
      addressAddition: ['', [Validators.required]],
      postalCode: [
        '',
        [Validators.required, Validators.maxLength(10)],
      ],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });
    this.emailControl.disable();
  }

  get item(): User {
    return this.service.formState.item;
  }
  get genderOptions(): SelectOption[] {
    return this.genderService.selectOptions;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }
  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  get firstnameControl(): UntypedFormControl {
    return this.form.get('firstname') as UntypedFormControl;
  }

  get lastnameControl(): UntypedFormControl {
    return this.form.get('lastname') as UntypedFormControl;
  }

  get confirmedGDPRControl(): UntypedFormControl {
    return this.form.get(
      'confirmedGDPR'
    ) as UntypedFormControl;
  }

  get roleAdminControl(): UntypedFormControl {
    return this.form.get('roleAdmin') as UntypedFormControl;
  }

  get roleCandidateControl(): UntypedFormControl {
    return this.form.get(
      'roleCandidate'
    ) as UntypedFormControl;
  }

  get genderControl(): UntypedFormControl {
    return this.form.get('gender') as UntypedFormControl;
  }
  get birthLocationControl(): UntypedFormControl {
    return this.form.get(
      'birthLocation'
    ) as UntypedFormControl;
  }

  get birthDateControl(): UntypedFormControl {
    return this.form.get('birthDate') as UntypedFormControl;
  }
  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }
  get email2Control(): UntypedFormControl {
    return this.form.get('email2') as UntypedFormControl;
  }
  get streetControl(): UntypedFormControl {
    return this.form.get('street') as UntypedFormControl;
  }
  get addressAdditionControl(): UntypedFormControl {
    return this.form.get(
      'addressAddition'
    ) as UntypedFormControl;
  }
  get postalCodeControl(): UntypedFormControl {
    return this.form.get(
      'postalCode'
    ) as UntypedFormControl;
  }
  get stateControl(): UntypedFormControl {
    return this.form.get('state') as UntypedFormControl;
  }
  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }

  submit() {
    this.form.markAllAsTouched();
    const values = this.form.value;
    this.logger.debug('save', { values });
    if (this.form.valid) {
      this.logger.debug('is submit');
      this.item.email = this.emailControl.value;
      this.item.firstname = this.firstnameControl.value;
      this.item.lastname = this.lastnameControl.value;
      this.service
        .save()
        .then(() => this.success())
        .catch((e: FormValidationErrorResponse) =>
          this.fail(e)
        );
    }
  }

  private success() {
    this.logger.debug('successful');
    // TODO message
    this.router.navigate(['/user']);
  }

  private fail(errors: FormValidationErrorResponse) {
    this.logger.debug('UserFormComponent:handleErrors', {
      errors,
    });
    errors.forEach((error) => {
      const field = this.form.get(
        error.field
      ) as UntypedFormControl;
      field.setErrors({ custom: error.message });
    });
  }
}
